import { useRecaptcha } from "@/hooks/useRecaptcha";
import { FormService } from "@/services/form";
import { ResponseSection, UpdateSectionPayload } from "@/services/form/types";
import { useCallback, useEffect, useState } from "react";

export function useSection({
  token,
  sectionId,
  getOnInit = true,
}: {
  token?: string;
  sectionId: string;
  getOnInit?: boolean;
}) {
  const { getRecaptcha } = useRecaptcha();

  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState<ResponseSection>();
  const load = useCallback(
    async (token: string) => {
      if (!sectionId) {
        return;
      }
      try {
        const formServiceInstance = new FormService(getRecaptcha);
        setLoading(true);
        const data = await formServiceInstance.getSection(token, sectionId);
        setSection(data);
      } finally {
        setLoading(false);
      }
    },
    [sectionId, getRecaptcha],
  );

  const update = useCallback(
    async (payload: UpdateSectionPayload) => {
      if (!token) {
        throw new Error("token undefined");
      }
      const formServiceInstance = new FormService(getRecaptcha);

      try {
        return await formServiceInstance.updateSection(
          token,
          sectionId,
          payload,
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [token, sectionId, getRecaptcha],
  );

  useEffect(() => {
    if (token && getOnInit) {
      load(token);
    }
  }, [load, getOnInit, token]);

  return {
    section,
    loading,
    update,
  };
}

export interface ResponseSection {
  id: string;
  title: string;
  forms: SectionForm[];
  complete: boolean;
}

export enum FieldsType {
  STRING_FORM_FIELD = "STRING_FORM_FIELD",
  DATE_FORM_FIELD = "DATE_FORM_FIELD",
  LONG_FORM_FIELD = "LONG_FORM_FIELD",
  FILE_FORM_FIELD = "FILE_FORM_FIELD",
}
export type UpdateSectionPayload = any;

export interface SectionForm {
  id: string;
  title: string;
  value: string;
  required: boolean;
  fields: FormField[];
  type: FormType;
}

export enum FormType {
  REQUIRED = "REQUIRED",
  LIST = "LIST",
  OPTIONAL = "OPTIONAL",
}
export enum FormFieldType {
  TEXT = "TEXT",
  DATE = "DATE",
  DROPDOWN = "DROPDOWN",
  MULTIDROPDOWN = "MULTIDROPDOWN",
  CONDITIONAL_SUBFORM = "CONDITIONAL_SUBFORM",
  CEP = "CEP",
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  CPF = "CPF",
  NUMBER = "NUMBER",
  MULTILINE_TEXT = "MULTILINE_TEXT",
  FILE = "FILE",
}

export interface FileValue {
  fileName: string;
  slot: number;
}

export type FormFieldValue = string[] | FileValue[] | null[];
export interface FormField {
  id: string;
  label: string;
  value: FormFieldValue;
  required: boolean;
  data?: FieldData;
  type: FormFieldType;
  hidden: boolean;
  description?: string;
}

export interface FieldData {
  options?: string[];
  showForOptionValues?: string[];
  size?: {
    min: number;
    max: number;
  };
  fields?: FormField[];
  accept?: string[];
  fileId?: string;
  maxSize?: number;
  additionalText?: string;
  referenceFileKey?: string;
  helpers?: string[]
}

export interface FormSection {
  id: string;
  complete: boolean;
  title: string;
}

export interface WelcomeMessage {
  title: string;
  message: string;
  contact: string;
}

export enum ResponseFormStatus {
  NOT_STARTED = "NOT_STARTED",
  FORM_IN_PROGRESS = "FORM_IN_PROGRESS",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  FINISHED = "FINISHED",
}

export interface ResponseForm {
  name: string;
  sections: any;
  addmitanceDate: string;
  welcomeMessage: WelcomeMessage;
  status: ResponseFormStatus;
  termOfServicesAcceptanceDate: string;
}

export interface ReponseFormData {
  type: string;
  policy: string;
  signature: string;
  date: string;
  credential: string;
  algorithm: string;
  contentType: string;
  key: string;
  bucket: string;
  region: string;
}

export interface ReponsePutFormData {
  error_backend_onboarding: {
    http_status_code: number;
    http_body: string;
  };
}

import styled from "styled-components";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";


export const Link = styled.a`
  color: ${Tokens.interfaceColor.primary.pure};
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  overflow-wrap: break-word;
  flex: 1;
  align-self: center
`;

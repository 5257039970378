import { useCallback } from "react";
import { FormService } from "@/services/form";
import { ReponseFormData } from "@/services/form/types";
import axios from "axios";
import { useRecaptcha } from "@/hooks/useRecaptcha";
import useNotification from "@/hooks/useNotification";

interface Props {
  token?: string;
  sectionId?: string;
}
function getExtesion(fileType: string) {
  return `.${fileType.split("/").pop()}`;
}

async function sendToBucket(meta: ReponseFormData, file: File) {
  const url = `https://${meta.bucket}.s3-${meta.region}.amazonaws.com/`;
  const data = new FormData();
  data.append("key", meta.key);
  data.append("Content-Type", meta.contentType);
  data.append("x-amz-credential", meta.credential);
  data.append("x-amz-algorithm", meta.algorithm);
  data.append("x-amz-date", meta.date);
  data.append("Policy", meta.policy);
  data.append("x-amz-signature", meta.signature);
  data.append("file", file as any);
  try {
    await axios({
      url,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export function useUploadFile(props: Props) {
  const { getRecaptcha } = useRecaptcha();
  const notification = useNotification();
  const upload = useCallback(
    async (
      file: File,
      fileId: string,
      fieldPath: string,
      position: number = 0,
    ) => {
      if (!props.token || !props.sectionId) {
        throw new Error("token or section id is undefined");
      }
      const formServiceInstance = new FormService(getRecaptcha);
      const extension = getExtesion(file.type);
      const payload = {
        extension,
        fileId,
        slot: position,
      };
      try {
        const bucketInfo = await formServiceInstance.createFormData(
          props.token,
          props.sectionId,
          payload,
        );
        await sendToBucket(bucketInfo, file);
        const value = file.name;
        await formServiceInstance.pathFile(props.token, props.sectionId, {
          fieldPath,
          value,
          position,
        });
      } catch (error) {
        console.error("UPLOAD", { error });
        notification.error("Erro ao fazer upload");
        throw error;
      }
    },
    [props.token, props.sectionId, getRecaptcha, notification],
  );

  return {
    upload,
  };
}

import { OnboardingApi } from "@/lib/axios";
import { ResponseForm } from "./types";

export class RegistrationService {
  constructor(private getRecaptcha: () => Promise<string | undefined>) {}

  async getForm(token: string) {
    const recaptcha = await this.getRecaptcha();
    const url = `/v1/registrations/${token}`;
    const response = await OnboardingApi(recaptcha).get<ResponseForm>(url);
    return response.data;
  }

  async acceptTos(token: string) {
    const recaptcha = await this.getRecaptcha();

    const url = `/v1/registrations/${token}/acceptance_date`;
    const response = await OnboardingApi(recaptcha).put(url);
    return response.data;
  }
}

import { FieldProps } from "../../helpers/types";
import DatePicker from "../DatePicker";

interface Props extends FieldProps<string | null | undefined> {
  helperText?: string;
}
export default function DateField(props: Props) {
  return (
    <DatePicker
      fullWidth
      parseFormat="yyyy-MM-dd"
      {...props}
      onChange={(date) => {
        props.onChange(date);
      }}
      value={props.value}
      error={!!props.error}
      helperText={props.helperText}
    />
  );
}

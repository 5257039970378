import styled from 'styled-components'
import { Tokens } from '@caju-beneficios/react-web-commons/dist/configs'

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`
export const Description = styled.p`
  font-size: 16px;
  color: ${Tokens.neutralColor.medium['400']};
`
export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`

import React from "react";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { Button, Grid, IconButton, LinearProgress } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";

interface Props {
  onSave: () => void;
  onBack: () => void;
  disableSubmit?: boolean;
  progress: number;
  saveText?:string
}
export default function FooterSubmit({
  onSave,
  onBack,
  disableSubmit,
  progress,
  saveText = 'Continuar'
}: Props) {
  return (
    <div>
      <LinearProgress
        style={{
          backgroundColor: Tokens.neutralColor.light[800],
          bottom: 80,
          position: "fixed",
          width: "100%",
          height: 8,
        }}
        variant="determinate"
        value={progress}
      />
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          padding: 16,
          zIndex: 100,
          backgroundColor: "#fff",
          height: 82,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs="auto">
            <IconButton color="primary" onClick={() => onBack()}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="contained"
              disabled={disableSubmit}
              color="primary"
              onClick={() => onSave()}
            >
              {saveText}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

import styled from "styled-components";

export const FileSelected = styled.div`
  /* max-width: 280px;
  width: 280px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Card from "@material-ui/core/Card";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import * as S from "./styles";

const useStyles = makeStyles({
  rootCard: {
    width: 375,
    height: 226,
    padding: 32,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginBottom: "8px",
    }
  },
});

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  title: string;
  description: string;
  onCancelText?: string;
  confirmText?: string;
  isLoading?: boolean;
}
export default function ConfirmDialog({
  onClose,
  onConfirm,
  isOpen,
  title,
  description,
  onCancelText = "Voltar",
  confirmText = "Confirmar",
  isLoading = false,
}: Props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog onClose={onClose} open={isOpen}>
        <Card className={classes.rootCard}>
          {isLoading ? (
            <div className={classes.container}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <>
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
              <S.Actions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onClose()}
                >
                  {onCancelText}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onConfirm()}
                >
                  {confirmText}
                </Button>
              </S.Actions>
            </>
          )}
        </Card>
      </Dialog>
    </div>
  );
}

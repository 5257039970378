import { createStyles, makeStyles } from "@material-ui/core/styles";
import WelcomeContainer from "@/components/WelcomeContainer";
import { Typography, Grid, Box } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import CallIcon from "@/assets/images/call.svg";
import JucaHappyIcon from "../../assets/images/juca_happy.svg";
import WelcomeFooter from "@/components/WelcomeFooter";
import { WelcomeMessage } from "@/services/registration/types";
import React from "react";
import { MdLink, MdOutlineEmail } from "react-icons/md";
import { Link } from "@/pages/AdmittanceWelcome/style";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
  }),
);

interface Props {
  welcomeMessage: WelcomeMessage;
  onNext: () => void;
  onPrevious: () => void;
}

export default function AdmittanceWelcome({
  welcomeMessage,
  onNext,
  onPrevious,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <WelcomeContainer>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ color: Tokens.neutralColor.dark[100] }}
        >
          Boas-vindas!
        </Typography>
        <div style={{ marginTop: 24 }} />
        <Box style={{ width: "100%" }}>
          <Typography
            variant="body1"
            gutterBottom
            style={{ color: Tokens.neutralColor.dark[200], fontSize: 16 }}
          >
            {welcomeMessage?.message}
          </Typography>
        </Box>
        <div style={{ marginTop: 45 }} />
        <Grid container spacing={2}>
          <Grid item spacing={2} xs={6} direction={"column"}>
            <Grid item>
              {welcomeMessage?.links.length !== 0 && (
                <>
                  <Typography
                    variant="caption"
                    gutterBottom
                    style={{ color: Tokens.neutralColor.medium[400] }}
                  >
                    Links
                  </Typography>
                  <div style={{ marginBottom: 10 }} />
                  {welcomeMessage?.links.map((link, index) => (
                    <Grid container style={{ marginBottom: 10 }} key={index}>
                      <MdLink
                        size={18}
                        style={{
                          alignSelf: "center",
                          marginRight: "5px",
                          color: Tokens.interfaceColor.primary.pure,
                        }}
                      />
                      <Link href={link.url} target={"_blank"}>
                        {link.name || link.url}
                      </Link>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
            <Grid item>
              {(welcomeMessage?.contact || welcomeMessage?.contactPhone) && (
                <>
                  <Typography
                    variant="caption"
                    gutterBottom
                    style={{ color: Tokens.neutralColor.medium[400] }}
                  >
                    Contato para dúvidas
                  </Typography>
                  <div style={{ marginBottom: 10 }} />
                  {welcomeMessage?.contact && (
                    <Grid container>
                      <MdOutlineEmail
                        size={18}
                        style={{
                          alignSelf: "center",
                          marginRight: "5px",
                          color: Tokens.interfaceColor.primary.pure,
                        }}
                      />
                      <Link
                        href={`mailto:${welcomeMessage?.contact}`}
                        target={"_blank"}
                      >
                        {welcomeMessage?.contact}
                      </Link>
                    </Grid>
                  )}
                  {welcomeMessage?.contactPhone && (
                    <Grid container>
                      <img
                        src={CallIcon}
                        alt={"Call"}
                        style={{ width: 18, marginRight: 5 }}
                      />
                      <Link
                        href={`tel:${welcomeMessage?.contactPhone}`}
                        target={"_blank"}
                      >
                        {welcomeMessage?.contactPhone}
                      </Link>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <img src={JucaHappyIcon} alt={"Juca Alegre"} />
            </Grid>
          </Grid>
        </Grid>
        <div style={{ marginBottom: 100 }} />
      </WelcomeContainer>
      <WelcomeFooter onSave={onNext} onBack={onPrevious} />
    </div>
  );
}

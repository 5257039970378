import { IconButton, Menu } from "@material-ui/core";
import React, { createContext } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import useActionButtons from "./useActionButtons";
const Context = createContext({
  closeMenu: () => {},
});

interface ActionButtonProps {
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
  customActionButtom?: React.ReactNode;
}

function ActionButton({ onClick, customActionButtom }: ActionButtonProps) {
  return (
    <div>
      {customActionButtom ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div onClick={onClick}>{customActionButtom}</div>
      ) : (
        <IconButton aria-label="ações" onClick={onClick} color="primary">
          <HiOutlineDotsVertical size={22} />
        </IconButton>
      )}
    </div>
  );
}

export default function ActionsButton({
  children,
  customActionButtom,
  customStyleMenu = {},
}: {
  children: React.ReactNode;
  customActionButtom?: React.ReactNode;
  customStyleMenu?: React.CSSProperties;
}) {
  const { closeMenu, menuPropsBind, togglerPropsBind } = useActionButtons();

  const values = {
    closeMenu,
  };

  return (
    <Context.Provider value={values}>
      <ActionButton
        {...togglerPropsBind}
        customActionButtom={customActionButtom}
      />
      <Menu
        style={{ marginTop: "35px", ...customStyleMenu }}
        {...menuPropsBind}
      >
        {children}
      </Menu>
    </Context.Provider>
  );
}

import { SectionForm } from "@/services/form/types";
import { getFormValuesComponent } from "../helpers";

interface Props {
  form: SectionForm;
  isMobile: boolean;
}

export default function FormValues(props: Props) {
  return (
    <div>
      {props.form.fields.filter(field => !field.hidden).map((field) =>
        getFormValuesComponent(field, props.isMobile)
      )}
    </div>
  );
}

import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { isValid, format } from "date-fns";
import { ReactNode, useEffect, useState } from "react";

interface Props extends Omit<KeyboardDatePickerProps, "onChange"> {
  onChange: (dateString: string | null, isValid: boolean) => void;
  parseFormat: string;
}

const defaultProps: Omit<KeyboardDatePickerProps, "value" | "onChange"> = {
  size: "small",
  format: "dd/MM/yyyy",
  inputVariant: "outlined",
  minDateMessage: "Não permitido datas passadas.",
  defaultValue: null,
};

const getErrorMsg = (
  isValid: boolean,
  hasError?: boolean | null,
  errorMsg?: string | null | ReactNode,
) => {
  if (!isValid) return "Data inválida";
  return errorMsg;
};
export default function DatePicker({ ...props }: Props) {
  const [currentDate, setCurrentDate] = useState<null | ParsableDate>(
    props.value ?? null,
  );

  useEffect(() => {
    if (props.value) {
      const date = new Date(props.value as string);
      const isValidDate = isValid(date);
      if (isValidDate) {
        setCurrentDate(date);
      }
    }
  }, [props.value]);

  const date = currentDate
    ? new Date(currentDate as Date).toLocaleDateString("en-US", {
        timeZone: "Etc/UTC",
      })
    : null;

  const handleChangeAndParse = (date: MaterialUiPickersDate) => {
    const dateIsValid = isValid(date);
    if (dateIsValid && date) {
      const formatDate = format(date, props.parseFormat);
      props.onChange(formatDate, true);
    }
    if (!dateIsValid) {
      props.onChange(null, false);
    }
  };

  return (
    <KeyboardDatePicker
      {...defaultProps}
      {...props}
      key={props.id}
      value={date}
      onChange={(_date) => {
        handleChangeAndParse(_date);
        setCurrentDate(_date);
      }}
      helperText={getErrorMsg(
        !currentDate || isValid(currentDate),
        props.error,
        props.helperText,
      )}
      error={props.error}
    />
  );
}

import React from "react";

import { FileValue, FormFieldType, SectionForm } from "@/services/form/types";
import LabelAndValueDefault from "../components/LabelAndValueDefault";
import LabelAndValueFile from "../components/LabelAndValueFile";
import LabelAndValueConditional from "../components/LabelAndValueConditional";

interface Props {
  form: SectionForm;
  isMobile: boolean;
  position: number;
}

export default function FormValuesList(props: Props) {
  return (
    <div>
      <>
        {props.form.fields
          .filter((field) => !field.hidden)
          .map((field) => {
            if (field.type === FormFieldType.FILE) {
              return (
                <LabelAndValueFile
                  label={field.label}
                  value={field.value[props.position] as FileValue}
                  isMobile={props.isMobile}
                  key={`${field.label}-${props.position}}`}
                />
              );
            }

            if (field.type === FormFieldType.MULTIDROPDOWN) {
              const parsedValue =
                typeof field.value === "string"
                  ? JSON.parse(field.value as unknown as string)
                  : field.value;

              if (typeof parsedValue !== "object") {
                const resultString = String(parsedValue).replace(/["[\]]/g, "");

                return (
                  <LabelAndValueDefault
                    label={field.label}
                    value={resultString}
                    type={field.type}
                    isMobile={props.isMobile}
                    key={`${field.label}-${props.position}}`}
                  />
                );
              }
              return (
                <LabelAndValueDefault
                  label={field.label}
                  value={String(parsedValue)}
                  type={field.type}
                  isMobile={props.isMobile}
                  key={`${field.label}-${props.position}}`}
                />
              );
            }

            if (field.type === FormFieldType.CONDITIONAL_SUBFORM) {
              return (
                <LabelAndValueConditional
                  key={`${field.label}-${props.position}}`}
                  label={field.label}
                  type={field.type}
                  value={field.value[0] as string}
                  fields={field.data?.fields}
                  isMobile={props.isMobile}
                />
              );
            }

            return (
              <LabelAndValueDefault
                label={field.label}
                value={field.value[props.position] as string}
                type={field.type}
                isMobile={props.isMobile}
                key={`${field.label}-${props.position}}`}
              />
            );
          })}
      </>
    </div>
  );
}

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectProps,
  FormControlProps,
  FormHelperText,
} from "@material-ui/core";
import React from "react";
import { FieldMultiDropdownProps } from "../../helpers/types";

type Props = Omit<SelectProps, "value" | "onChange" | "error"> &
  FieldMultiDropdownProps & {
    size: FormControlProps["size"];
    helperText?: string;
  };

export default function FieldMultiDropdown(props: Props) {
  return (
    <FormControl
      variant="outlined"
      size={props.size}
      fullWidth
      disabled={props.disabled}
      error={!!props.error}
    >
      <InputLabel
        shrink={Boolean(props.value?.length)}
        style={{ background: "#fff", padding: "0px 2px" }}
      >
        {props.label}
      </InputLabel>
      <Select
        multiple
        data-testid="multidropdown"
        labelId={`${props.id}-select`}
        id={`${props.id}-select`}
        value={props.value ? props.value : []}
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event?.target.value as Array<string>);
          }
        }}
        placeholder={props.placeholder}
        label={props.label}
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {props.options?.map((item) => {
          return (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}

import React from "react";
import { BsCheckCircle } from "react-icons/bs";

import * as S from "./styles";
import { useMediaQuery, Theme } from "@material-ui/core";
import { FormSection } from "@/services/form/types";
interface Props {
  token?: string;
  sections?: FormSection[];
  goTo: (sectionId: string) => void;
}

export default function SectionsCompleted(props: Props) {
  const showSectionsCompleted = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm"),
  );

  const sectionsLabel =
    props.sections?.map((section) => {
      return {
        id: section.id,
        label: section.title,
      };
    }) ?? [];

  const handleClick = (sectionId: string, isCompleted?: boolean) => {
    if (isCompleted) {
      props.goTo(sectionId);
    }
  };

  if (!showSectionsCompleted) return null;
  return (
    <div>
      <S.Title> Formulário de admissão</S.Title>

      <S.FormInfo>
        {sectionsLabel.map((sec) => {
          const isCompleted = props?.sections?.some(
            (section) => section.title === sec.label && section.complete,
          );
          return (
            <S.SectionTitle
              isCompleted={isCompleted}
              key={sec.id}
              onClick={() => handleClick(sec.id, isCompleted)}
            >
              <BsCheckCircle size={20} /> <p>{sec.label}</p>
            </S.SectionTitle>
          );
        })}
      </S.FormInfo>
    </div>
  );
}

import { useCallback, useMemo } from 'react'
import { useSnackbar } from 'notistack'
enum VariantType {
  success = 'success',
  error = 'error',
}

function useNotification() {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  const notify = useCallback(function notify(content: string, variant: VariantType, option: any): void {
    const key = enqueueSnackbar(content, {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      autoHideDuration: 5000,
      onClick: () => closeSnackbar(key),
      variant,
      ...option,
    })
  }, [ closeSnackbar, enqueueSnackbar ])

  const success = useCallback(function success(content: string, option: any = {}): void {
    notify(content, VariantType.success, option)
  }, [ notify ])

  const error = useCallback(function error(content: string, option: any = {}): void {
    notify(content, VariantType.error, option)
  }, [ notify ])

  return useMemo(() => ({
    error,
    success,
  }), [ error, success ])
}

export default useNotification

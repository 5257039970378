import {
  Tooltip,
  IconButton,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 0,
      marginLeft: 16,
      borderRadius: "50%",
      color: Tokens.neutralColor.medium[400],
      "&:hover": {
        background: "transparent",
      },
    },
  })
);

type Props = {
  message: string;
};

export default function FieldTooltip(props: Props) {
  const classes = useStyles();

  return (
    <Tooltip arrow title={props.message} className={classes.root}>
      <IconButton aria-label="info" color="primary">
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}

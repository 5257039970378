import { AddressByZipCode } from "@/services/viaCep/types";

const keysFieldsAddress: { [key: string]: string } = {
  logradouro: "ADDRESS_LINE_1",
  bairro: "NEIGHBORHOOD",
  uf: "STATE",
  localidade: "CITY",
  cep: 'ZIPCODE'
};

export const transformAddressObj = (address: AddressByZipCode) => {
  return Object.entries(address).map(([key, value]) => {
    return {
      fieldId: keysFieldsAddress[key],
      value,
    };
  });
};

import { FormFieldType } from "@/services/form/types";
import * as S from "../styles";
import { format, parseISO } from "date-fns";

export default function LabelAndValueDefault({
  label,
  value,
  isMobile,
  type,
}: {
  label: string;
  value: string | null;
  type: FormFieldType;
  isMobile: boolean;
}) {
  const getParseValue = () => {
    if (!value) return "";
    if(type === FormFieldType.MULTIDROPDOWN) {
      const parsedValue = value?.replace(/["[\]]/g, '');
      return parsedValue;
    }
    if (type === FormFieldType.DATE) {
      const parsedDate = parseISO(value);
      const transformedDate = format(parsedDate, "dd/MM/yyyy");
      return transformedDate;
    }

    return value;
  };
  return (
    <S.FormInfo isMobile={isMobile}>
      <S.FieldLabel>{label}</S.FieldLabel>
      <S.FieldValue>{getParseValue()}</S.FieldValue>
    </S.FormInfo>
  );
}

import { useRecaptcha } from "@/hooks/useRecaptcha";
import { FormsResponseService } from "@/services/form_responses";
import { useCallback, useState } from "react";

export function useFormsResponse(token?: string) {
  const { getRecaptcha } = useRecaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const getFileUrl = useCallback(
    async (sectionId: string, fileKey: string) => {
      setIsLoading(true);
      if (!token) {
        throw new Error("[useFormsResponse] - Token invalid");
      }
      try {
        const serviceInstance = new FormsResponseService(getRecaptcha);
        const data = serviceInstance.getFileUrl(token, sectionId, fileKey);
        return data;
      } finally {
        setIsLoading(false);
      }
    },
    [getRecaptcha, token]
  );

  return {
    getFileUrl,
    isLoading,
  };
}

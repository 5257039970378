import { useState, MouseEvent } from 'react'

function useActionButtons() {
  const [element, setElmeent] = useState<Element>()

  function onClick(event: MouseEvent): void {
    setElmeent(event.currentTarget)
  }

  function onClose(): void {
    setElmeent(undefined)
  }

  const isOpened = element !== undefined

  return {
    closeMenu: () => setElmeent(undefined),
    isOpened,
    menuPropsBind: {
      anchorEl: element,
      onClose,
      open: isOpened,
    },
    onClick,
    togglerPropsBind: {
      onClick,
    },
  }
}

export default useActionButtons

import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
export const FormInfo = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const SectionTitle = styled.div<{ isCompleted?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    color: ${(props) =>
      props.isCompleted
        ? Tokens.feedbackColor.success["pure"]
        : Tokens.neutralColor.medium["400"]};
    margin: 0;
  }
  cursor: ${(props) => (props.isCompleted ? "pointer" : "default")};

  svg {
    fill: ${(props) =>
      props.isCompleted
        ? Tokens.feedbackColor.success["pure"]
        : Tokens.neutralColor.medium["400"]};
  }
`;

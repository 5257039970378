import React, { useRef } from "react";
import { FormSection, FormType, SectionForm } from "@/services/form/types";
import { Grid, Theme, useMediaQuery } from "@material-ui/core";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import * as S from "./styles";
import FooterSubmit from "../../../components/FooterSubmit";
import SectionsCompleted from "../../../components/SectionsCompleted";
import { useNavigateSection } from "../../hooks/useNavigateSection";
import DefaultForm, { DefaultFormHandle } from "../FormsByType/DefaultForm";
import FormList from "../FormsByType/FormList";
import { useSection } from "@/hooks/services/form/useSection";
import {
  getFileConditionalFieldsFile,
  getFileFields,
  parseFields,
} from "./helpers";
import { useUploadFile } from "./hooks/useUploadFile";
import useNotification from "@/hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { useResponseForm } from "@/hooks/services/registration/useResponseForm";
import { useCompletedSections } from "@/pages/AdmittanceDash/useCompletedSections";
import { GlobalLoading } from "@caju-beneficios/react-web-commons";

interface Props {
  forms?: SectionForm[];
  sectionTitle?: string;
  sectionId: string;
  token?: string;
  sections?: FormSection[];
  isFromSummary?: boolean;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

export default function Forms(props: Props) {
  const responseFormService = useResponseForm(props.token as string, true);
  const { percentComplete } = useCompletedSections(
    responseFormService.responseForm?.sections,
  );

  const notification = useNotification();
  const navigate = useNavigate();
  const uploadFile = useUploadFile({
    sectionId: props.sectionId,
    token: props.token,
  });
  const navigateSection = useNavigateSection({
    currentSection: props.sectionId,
    sections: responseFormService.responseForm?.sections,
  });
  const formsRef = useRef<DefaultFormHandle[]>([]);
  const sectionService = useSection({
    sectionId: props.sectionId,
    getOnInit: false,
    token: props.token,
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs"),
  );

  async function handleSubmitForms() {
    const formsResult = await Promise.all(
      formsRef.current
        .filter((formRef) => formRef)
        .map(async (formRef) => {
          const [isValid, values] = await formRef.submitForm();
          return { isValid, values };
        }),
    );
    const isAllValid = formsResult.every((form) => form.isValid);

    const formsValues = formsResult.map((form) => form.values);
    const formsWithFieldFile = getFileFields(formsValues);
    const conditionalFieldsFile = getFileConditionalFieldsFile(formsValues);

    if (isAllValid) {
      const payload = formsValues.map((form) => {
        return {
          id: form?.formId,
          fields: parseFields(form?.fields),
        };
      });
      const fieldsFile = [...conditionalFieldsFile, ...formsWithFieldFile];
      props.setIsLoading(true);
      try {
        await Promise.all(
          fieldsFile.map((form) => {
            return Promise.all(
              form.fields.map(async (field: any) => {
                for (const [index, fieldFile] of field.value.entries()) {
                  const fieldPath = `${form.formId}.${field.id}`;
                  if (fieldFile instanceof File) {
                    await uploadFile.upload(
                      fieldFile,
                      field.data.fileId,
                      fieldPath,
                      index,
                    );
                  }
                  if (Array.isArray(fieldFile)) {
                    await Promise.all(
                      fieldFile.map(async (file, index: number) => {
                        if (file instanceof File) {
                          await uploadFile.upload(
                            file,
                            field.data.fileId,
                            fieldPath,
                            index,
                          );
                        }
                        return file;
                      }),
                    );
                  }
                }
              }),
            );
          }),
        );
        await sectionService.update({ forms: payload });
        if (props.isFromSummary) {
          navigate(`/section/form/summary/${props.token}`);
        } else {
          navigateSection.next(props.token);
        }
      } catch (error: any) {
        notification.error("Algo deu errado.");
        console.error("submit error", { error });
        props.setIsLoading(false);
      }
    }
  }

  return (
    <>
      {(props.isLoading || responseFormService.loading) && (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
          style={{ height: "100vh" }}
        >
          <GlobalLoading show />
        </Grid>
      )}
      <div hidden={props.isLoading || responseFormService.loading}>
        <Grid
          container
          justifyContent="center"
          direction={isMobile ? "column" : "row"}
          alignContent="center"
          style={{ marginTop: 80 }}
        >
          <Grid item md={3} sm={4} lg={2}>
            <SectionsCompleted
              token={props.token}
              key={props.sectionId}
              sections={responseFormService.responseForm?.sections}
              goTo={(sectionId) => navigateSection.goTo(sectionId, props.token)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sm={6}
            lg={5}
            style={{
              border: `1px solid ${Tokens.neutralColor.medium["700"]}`,
              borderWidth: isMobile ? 0 : 1,
              borderRadius: Tokens.borderRadius.medium,
              padding: 16,
              marginBottom: 120,
            }}
          >
            <S.TitleForm>{props.sectionTitle}</S.TitleForm>
            {props.forms?.map((form, indexForm) => {
              const isFormList = form.type === FormType.LIST;

              if (isFormList) {
                return (
                  <FormList
                    ref={(el: never) => (formsRef.current[indexForm] = el)}
                    key={form.id}
                    form={form}
                    isMobile={isMobile}
                  />
                );
              }

              return (
                <DefaultForm
                  ref={(el: never) => (formsRef.current[indexForm] = el)}
                  key={form.id}
                  form={form}
                  isMobile={isMobile}
                />
              );
            })}
          </Grid>
        </Grid>

        <FooterSubmit
          onBack={() => navigateSection.back(props.token)}
          onSave={async () => await handleSubmitForms()}
          progress={percentComplete}
        />
      </div>
    </>
  );
}

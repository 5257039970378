import { FileValue, FormField, FormFieldType } from "@/services/form/types";
import LabelAndValueFile from "../components/LabelAndValueFile";
import LabelAndValueDefault from "../components/LabelAndValueDefault";
import LabelAndValueConditional from "../components/LabelAndValueConditional";


export const getFormValuesComponent = (field: FormField, isMobile: boolean) => {
  const isFile = field.type === FormFieldType.FILE;
  const isMultidropdown = field.type === FormFieldType.MULTIDROPDOWN;
  const isConditionalForm = field.type === FormFieldType.CONDITIONAL_SUBFORM;

  if (isMultidropdown) {
    const parsedValue = typeof field.value === "string" ? JSON.parse(field.value as unknown as string) : field.value;

    if(typeof parsedValue !== "object") {
      const resultString = String(parsedValue).replace(/["[\]]/g, '');

      return (
        <LabelAndValueDefault
        isMobile={isMobile}
        label={field.label}
        value={resultString}
        type={field.type}
        />
      );
    }

    return (
      <LabelAndValueDefault
        isMobile={isMobile}
        label={field.label}
        value={String(parsedValue)}
        type={field.type}
      />
    );
  }
  if (isFile)
    return (
      <LabelAndValueFile
        isMobile={isMobile}
        label={field.label}
        value={field.value[0] as FileValue}
      />
    );
  if (isConditionalForm)
    return (
      <LabelAndValueConditional
        isMobile={isMobile}
        label={field.label}
        type={field.type}
        value={field.value[0] as string}
        fields={field.data?.fields}
      />
    );
  return (
    <LabelAndValueDefault
      isMobile={isMobile}
      label={field.label}
      value={field.value[0] as string}
      type={field.type}
    />
  );
};

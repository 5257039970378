import styled from "styled-components";
import { MaterialDesignContent } from "notistack";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";

const CommonProps = {
  fontSize: Tokens.fontSize.sm,
  fontWeight: Tokens.fontWeight.semibold.toString(),
  boxShadow: 'none',
  borderRadius: Tokens.borderRadius.medium,
}

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: Tokens.feedbackColor.success.light,
    color: Tokens.feedbackColor.success.dark,
    ...CommonProps
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: Tokens.feedbackColor.error.light,
    color: Tokens.feedbackColor.error.dark,
    ...CommonProps
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: Tokens.feedbackColor.warning.light,
    color: Tokens.feedbackColor.warning.dark,
    ...CommonProps
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: Tokens.interfaceColor.primary.light,
    color: Tokens.interfaceColor.primary.dark,
    ...CommonProps
  },
}));
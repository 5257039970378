import { Tokens } from '@caju-beneficios/react-web-commons/dist/configs';
import { Button } from '@material-ui/core'
import { ReactNode } from 'react';
import { MdArrowBackIos } from 'react-icons/md'

interface Props {
  onSave: () => void;
  onBack: () => void;
  disableSubmit?: boolean;
  children?: ReactNode
}

export default function WelcomeFooter({
  onSave,
  onBack,
  disableSubmit = false,
  children
}: Props) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 25px 0 40px',
            backgroundColor: Tokens.neutralColor.light[1000],
            borderTop: `1px solid ${Tokens.neutralColor.medium[700]}`,
            minHeight: children ? 190 : 98,
            width: '100%',
            position: 'fixed',
            bottom: 0
        }}>
            {children}
            <div style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <MdArrowBackIos style={{ cursor: 'pointer' }} size={24} onClick={() => onBack()} color={Tokens.interfaceColor.primary.pure} />
                <Button
                    variant='contained'
                    disabled={disableSubmit}
                    color='primary'
                    onClick={() => onSave()}
                >
                    Próximo
                </Button>
            </div>
        </div>
    );
}
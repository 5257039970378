import { OnboardingApi } from "@/lib/axios";

export class FormsResponseService {
  constructor(private getRecaptcha: () => Promise<string | undefined>) {}

  async getFileUrl(token: string, sectionId: string, fileKey: string) {
    const recaptcha = await this.getRecaptcha();
    const url = `/v1/form_responses/${token}/sections/${sectionId}/file_url`;
    const response = await OnboardingApi(recaptcha).post<string>(url, {
      key: fileKey,
    });
    return response.data;
  }
}

import { useEffect, useState } from "react";
import { RWCMUIThemeProvider } from "@caju-beneficios/react-web-commons";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { RouterProvider } from "react-router";
import { CssBaseline } from "@material-ui/core";
import router from "./router";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { StyledMaterialDesignContent } from "./components/StyledMaterialDesignContent";
import { SnackbarProvider } from "notistack";
import { initMonitoring } from "./lib/datadog/datadog.config";


declare global {
  interface Window {
    executeRecaptcha?: ReturnType<typeof useGoogleReCaptcha>['executeRecaptcha'];
  }
}

function RecaptchaSaveWindow() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (executeRecaptcha) {
      window.executeRecaptcha = executeRecaptcha;
    }
  }, [executeRecaptcha]);

  return <div />;
}
function App() {
  const [showApp, setShowApp] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  if (!executeRecaptcha) return null;

  if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
    initMonitoring();
  }

  return (
    <div className="App" style={{ height: "100vh" }}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
      >
        <RecaptchaSaveWindow />
        <RWCMUIThemeProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
            <SnackbarProvider
              Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
              }}
            >
              <CssBaseline />
              <GoogleReCaptcha onVerify={() => setShowApp(true)} />
              {showApp ? <RouterProvider router={router} /> : null}
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </RWCMUIThemeProvider>
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;

import React from "react";
import AppBar from "@/components/AppBar";
import SummarySection from "./SummarySection";
import { Grid } from "@material-ui/core";
import FooterSubmit from "../components/FooterSubmit";
import { useNavigate, useParams } from "react-router-dom";
import SectionsCompleted from "../components/SectionsCompleted";
import { useResponseForm } from "@/hooks/services/registration/useResponseForm";
import { useVisibilty } from "@caju-beneficios/react-web-commons";
import ConfirmDialog from "@/components/ConfirmDialog";
import { useFormSubmit } from "@/hooks/services/form/useFormSubmit";
import useNotification from "@/hooks/useNotification";
import { useNavigateSection } from "../FormBySection/hooks/useNavigateSection";
import { useCompletedSections } from "../AdmittanceDash/useCompletedSections";
import { Helmet } from "react-helmet";

export default function FormsSummary() {
  const params = useParams<{ token: string }>();
  const notification = useNotification();
  const navigate = useNavigate();
  const responseFormService = useResponseForm(params.token as string, true);
  const { percentComplete } = useCompletedSections(
    responseFormService.responseForm?.sections,
  );
  const dialogConfirm = useVisibilty();
  const serviceSubmit = useFormSubmit();

  async function handleSubmitForm() {
    try {
      await serviceSubmit.submit(params.token);
      navigate(`/home/token/${params.token}`);
      notification.success("Formulário enviado");
      dialogConfirm.toggle();
    } catch (error) {
      notification.error("Algo deu errado.");
    }
  }

  const navigateSection = useNavigateSection();

  const lastSessionId = responseFormService.responseForm?.sections?.at(-1)?.id;
  return (
    <div>
      <Helmet>
        <title>Resumo</title>
      </Helmet>
      <ConfirmDialog
        title="Enviar formulário"
        description="O formulário de admissão será enviado à empresa e não será mais possível editar os campos."
        isOpen={dialogConfirm.isVisible}
        onClose={() => dialogConfirm.toggle()}
        isLoading={serviceSubmit.loading}
        onConfirm={async () => {
          await handleSubmitForm();
        }}
      />
      <AppBar />
      <Grid container justifyContent="center">
        <Grid item md={3} sm={4} lg={2} style={{ marginTop: 24 }}>
          <SectionsCompleted
            token={params.token}
            sections={responseFormService.responseForm?.sections}
            goTo={(sectionId) =>
              navigateSection.goTo(sectionId, params.token, true)
            }
          />
        </Grid>
        <Grid item xs={11} md={6} sm={6} lg={5}>
          {responseFormService.responseForm?.sections.map((section) => (
            <SummarySection sectionId={section.id} key={section.id} />
          ))}
        </Grid>
      </Grid>
      <FooterSubmit
        onBack={() => navigateSection.goTo(lastSessionId, params.token, true)}
        onSave={() => dialogConfirm.toggle()}
        progress={percentComplete}
        saveText="Enviar formulário"
      />
    </div>
  );
}

import { createStyles, makeStyles } from "@material-ui/core/styles";
import ButtonAppBar from "@/components/AppBar";
import { useResponseForm } from "@/hooks/services/registration/useResponseForm";
import { useState , useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdmittanceDash from "../AdmittanceDash";
import AdmittanceWelcome from "../AdmittanceWelcome";
import AdmittanceTOS from "../AdmittanceTOS";
import { LocalLoading } from "@caju-beneficios/react-web-commons";
import { useNavigateSection } from "../FormBySection/hooks/useNavigateSection";
import { ResponseFormStatus } from "@/services/registration/types";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100%",
    },
  }),
);

export enum Steps {
  HOME = "HOME",
  WELCOME_MESSAGE = "WELCOME_MESSAGE",
  TOS = "TOS",
}

export default function AdmittanceHome() {
  const classes = useStyles();
  const params = useParams();
  const [steps, setSteps] = useState(Steps.HOME);
  const navigate = useNavigate();
  const navigateSection = useNavigateSection();
  const responseFormService = useResponseForm(params.token as string, true);
  const handleClickFillForm = useCallback(() => {
    // se status for not_started -> começa no welcome message
    if (
      responseFormService.responseForm?.status ===
      ResponseFormStatus.NOT_STARTED
    ) {
      setSteps(Steps.WELCOME_MESSAGE);
      return;
    }
    if (
      responseFormService.responseForm?.status ===
      ResponseFormStatus.FORM_IN_PROGRESS
    ) {
      // se status for form_in_progress -> seguir a lógica de baixo
      const nextSection = responseFormService.responseForm?.sections.find(
        (section) => !section.complete,
      );
      if (nextSection) {
        navigateSection.goTo(nextSection.id, params.token as string);
      }
      if (!nextSection) {
        navigate(`/section/form/summary/${params.token}`);
      }
    }
  }, [
    responseFormService.responseForm,
    navigateSection,
    navigate,
    params.token,
  ]);

  const handleClickStartSection = useCallback(() => {
    const nextSection = responseFormService.responseForm?.sections.find(
      (section) => !section.complete,
    );
    if (nextSection) {
      navigateSection.goTo(nextSection.id, params.token as string);
    }
  }, [responseFormService.responseForm, navigateSection, params.token]);

  return (
    <div className={classes.root}>
      <ButtonAppBar />
      {responseFormService.error &&
      ![
        "registration fill period ended",
        "Token invalid",
        "registration not found",
      ].includes(responseFormService.error) ? (
        <p>Token inválido</p>
      ) : null}
      {responseFormService.loading && responseFormService.responseForm ? (
        <div
          style={{
            flexGrow: 1,
            height: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LocalLoading show />
        </div>
      ) : (
        <>
          {steps === Steps.HOME ? (
            <AdmittanceDash
              loading={responseFormService.loading}
              responseForm={responseFormService.responseForm}
              onFillForm={handleClickFillForm}
              error={responseFormService.error}
            />
          ) : null}
          {steps === Steps.WELCOME_MESSAGE &&
          responseFormService.responseForm?.welcomeMessage ? (
            <AdmittanceWelcome
              welcomeMessage={responseFormService.responseForm?.welcomeMessage}
              onNext={() => setSteps(Steps.TOS)}
              onPrevious={() => setSteps(Steps.HOME)}
            />
          ) : null}
          {steps === Steps.TOS &&
          responseFormService.responseForm?.welcomeMessage ? (
            <AdmittanceTOS
              token={params.token as string}
              onNext={handleClickStartSection}
              onPrevious={() => setSteps(Steps.WELCOME_MESSAGE)}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

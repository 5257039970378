import FormBySection from "@/pages/FormBySection";
import FormsSummary from "@/pages/FormsSummary";
import { createBrowserRouter } from "react-router-dom";
import AdmittanceHome from "@/pages/AdmittanceHome";
import { useEffect } from "react";

const EmployeeManagementRedirect = () => {
  useEffect(() => {
    window.location.href = "https://colaborador.caju.com.br/portal";
  }, []);
  return <></>;
};

const router = createBrowserRouter([
  {
    path: "section/form/token/:token",
    element: <FormBySection />,
  },
  {
    path: "section/form/summary/:token",
    element: <FormsSummary />,
  },
  {
    path: "/home/token/:token",
    element: <AdmittanceHome />,
  },
  {
    path: "/",
    element: <EmployeeManagementRedirect />,
  },
]);

export default router;

import React from "react";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { Divider, Grid, Theme, useMediaQuery } from "@material-ui/core";
import { BiEditAlt } from "react-icons/bi";
import * as S from "./styles";
import { useNavigateSection } from "@/pages/FormBySection/hooks/useNavigateSection";
import { FormType } from "@/services/form/types";
import { useSection } from "@/hooks/services/form/useSection";
import { useParams } from "react-router-dom";
import FormValues from "./FormValues";
import FormValuesList from "./FormValuesList";
import {
  createFormsByValuesLength,
  generateFormsPositions,
} from "@/pages/FormBySection/components/FormsByType/helpers";

interface Props {
  sectionId: string;
}

export default function SummarySections(props: Props) {
  const params = useParams<{ token: string }>();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { section } = useSection({
    sectionId: props.sectionId,
    token: params.token,
  });

  const navigateSection = useNavigateSection();

  return (
    <Grid
      container
      justifyContent="center"
      style={{ marginTop: 24, marginBottom: 110 }}
    >
      <Grid
        item
        xs={12}
        style={{
          border: `1px solid ${Tokens.neutralColor.medium["700"]}`,
          borderWidth: 1,
          borderRadius: Tokens.borderRadius.medium,
          padding: 24,
        }}
      >
        <S.SectionConfig>
          <S.SectionTitle>{section?.title}</S.SectionTitle>
          <S.SectionEdit
            onClick={() => {
              navigateSection.goTo(props.sectionId, params.token, true);
            }}
          >
            <p>Editar</p> <BiEditAlt />
          </S.SectionEdit>
        </S.SectionConfig>
        {section?.forms.map((form, index) => {
          const isList = form.type === FormType.LIST;

          if (isList) {
            const formPositions = createFormsByValuesLength(form.fields);

            return (
              <div key={index}>
                {generateFormsPositions(formPositions).map((position) => {
                  return (
                    <div key={index}>
                      <S.FormTitle>{form.title}</S.FormTitle>
                      <FormValuesList
                        form={form}
                        isMobile={isMobile}
                        key={form.id}
                        position={position}
                      />
                      <Divider variant="middle" style={{ margin: "24px 0" }} />
                    </div>
                  );
                })}
              </div>
            );
          }
          return (
            <div key={index}>
              <S.FormTitle>{form.title}</S.FormTitle>
              <FormValues form={form} isMobile={isMobile} key={form.id} />
              <Divider variant="middle" style={{ margin: "24px 0" }} />
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
}

import { useRecaptcha } from "@/hooks/useRecaptcha";
import { RegistrationService } from "@/services/registration";
import { ResponseForm } from "@/services/registration/types";
import { AxiosError } from "axios";
import React, { useEffect } from "react";

const ENTITY_TOKEN_INVALID = "DecryptRegistrationToken";

export function useResponseForm(token?: string, onInit: boolean = false) {
  const { getRecaptcha } = useRecaptcha();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [responseForm, setResponseForm] = React.useState<ResponseForm>();

  const getResponseForm = React.useCallback(async () => {
    if (!token) {
      throw new Error("[getResponseForm] - Token invalid");
    }
    try {
      const registrationServiceInstance = new RegistrationService(getRecaptcha);
      setError(null);
      setLoading(true);
      const form = await registrationServiceInstance.getForm(token);
      setResponseForm(form);
      return {
        responseForm: form,
      };
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        const errorMessage =
          e.response?.data?.message?.errors?.length > 0
            ? e.response?.data?.message?.errors[0]?.data?.id
            : null;
        setError(errorMessage);
        if (
          e.response?.data?.message?.errors?.length > 0 &&
          e.response?.data?.message?.errors[0]?.data?.entity ===
            ENTITY_TOKEN_INVALID
        ) {
          return;
        }
      }
      throw e;
    } finally {
      setLoading(false);
    }
  }, [token, getRecaptcha]);

  useEffect(() => {
    if (onInit) {
      getResponseForm();
    }
  }, [getResponseForm, onInit]);

  return { responseForm, getResponseForm, loading, error };
}

import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { isNumber } from "lodash";
const baseURL = process.env.REACT_APP_ONBOARDING_URL_API;

interface CustomAxiosConfig extends AxiosRequestConfig {
  _attempt?: number;
}

const axiosInstance: AxiosInstance = Axios.create({
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

axiosInstance.interceptors.response.use(
  (resp) => resp,
  async (err: AxiosError<any>) => {
    const originalRequest = err.config as CustomAxiosConfig;
    if (err.response?.status === 403) {
      if (!window.executeRecaptcha) {
        return Promise.reject(err);
      }
      const newToken = await window?.executeRecaptcha();
      originalRequest._attempt = isNumber(originalRequest._attempt)
        ? originalRequest._attempt + 1
        : 1;

      if (Number(originalRequest._attempt) <= 10) {
        if (originalRequest.headers) {
          originalRequest.headers["x-caju-captcha"] = newToken;
        }
        await new Promise((r) => setTimeout(r, 1000));
        return axiosInstance(originalRequest);
      }
    }
    return Promise.reject(err);
  },
);

export const OnboardingApi = (captcha: string | undefined) => {
  if (captcha) {
    axiosInstance.defaults.headers["x-caju-captcha"] = captcha;
  } else {
    delete axiosInstance.defaults.headers["x-caju-captcha"];
  }
  return axiosInstance;
};

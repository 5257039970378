import { FieldData, FormField, FormFieldType } from "@/services/form/types";
import { cpf } from "cpf-cnpj-validator";
import { parse } from "date-fns";
import * as Yup from "yup";

interface InitialValues {
  [key: string]: any;
}

export function createSubFieldsData(data?: FieldData) {
  return data?.fields?.reduce((acc, field) => ({ ...acc, [field.id]: "" }), {});
}


const specialTypes = [FormFieldType.CONDITIONAL_SUBFORM,FormFieldType.MULTIDROPDOWN]
export function createInitialValues({
  fields,
  isList,
}: {
  fields: FormField[];
  isList?: boolean;
}) {
  const initialValues: InitialValues = {};
  for (const field of fields) {
    if (field.type === FormFieldType.MULTIDROPDOWN) {
      const valuesString = field.value[0] as string;

      let values: string[] = [];

      if(valuesString?.length) {
        try {
          values = JSON.parse(valuesString);
          if(values.length) {
            initialValues[field.id] = values;
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      }
    }

    if (field.type === FormFieldType.CONDITIONAL_SUBFORM) {
      initialValues[field.id] = {
        value: Array.isArray(field.value) ? field.value[0] : field.value ?? null ,
        data: {
          fields: field?.data?.fields?.reduce((acc, subField) => {
            return {
              ...acc,
              [subField.id]: isList
                ? subField.value
                : subField.value[0] ?? null,
            };
          }, {}),
        },
      };
    }

    if (!specialTypes.includes(field.type)) {
      const _valueList = field.value.length ? field.value : [];

      initialValues[field.id] = isList ? _valueList : field.value[0] || null;
    }
  }

  return initialValues;
}

export function generateValidationSchema(fields?: FormField[]) {
  const schemaObject: Record<string, Yup.AnySchema> = {};

  fields?.forEach((field) => {
    let fieldSchema = Yup.string().nullable();

    if (field.required) {
      if (field.type === FormFieldType.FILE) {
        schemaObject[field.id] = Yup.mixed().required(
          `${field.label} é obrigatório`
        );
      }

      if (
        field.type !== FormFieldType.CONDITIONAL_SUBFORM &&
        field.type !== FormFieldType.FILE
      ) {
        fieldSchema = fieldSchema.required(`${field.label} é obrigatório.`);
        schemaObject[field.id] = fieldSchema;
      }
    }

    if (field.type === FormFieldType.MULTIDROPDOWN) {
      schemaObject[field.id] = Yup.array()
      .of(
        Yup.string().required(
          field.required ? `${field.label} é obrigatório` : undefined
        )
      )
      .default(field.value as any);
    }

    if (field.type === FormFieldType.CPF) {
      fieldSchema = fieldSchema.test("cpf-valid", "CPF Inválido", (value) =>
        value ? cpf.isValid(value) : false
      );
      schemaObject[field.id] = fieldSchema;
    }

    if (field.data?.size?.min) {
      fieldSchema = fieldSchema.min(
        field.data?.size?.min,
        `${field.label} deve conter no mínimo ${field.data.size.min} caracteres`
      );
      schemaObject[field.id] = fieldSchema;
    }
    if (field.data?.size?.max) {
      fieldSchema = fieldSchema.max(
        field.data?.size?.max,
        `${field.label} deve conter no máximo ${field.data.size.max} caracteres`
      );
      schemaObject[field.id] = fieldSchema;
    }
    if (field.type === FormFieldType.DATE && field.required) {
      fieldSchema = fieldSchema.test(
        `${field.id}-invalid-date`,
        "Data inválida",
        (value) => {
          if (value) {
            const parsedDate = parse(String(value), "yyyy-MM-dd", new Date());
            const dateIsValid = !isNaN(parsedDate.getTime());

            if (dateIsValid) {
              return true;
            }

            if (!dateIsValid) {
              return false;
            }
          }
          return false;
        }
      );

      schemaObject[field.id] = fieldSchema;
    }

    if (field.type === FormFieldType.CONDITIONAL_SUBFORM) {
      schemaObject[field.id] = Yup.object().shape({
        value: Yup.string()
          .nullable()
          .required(() =>
            field.required ? `${field.label} é obrigatório` : undefined
          ),
        data: Yup.object().when("value", ([value], schema) => {
          if (field.data?.showForOptionValues?.includes(value)) {
            return schema.shape({
              fields: Yup.object().shape(
                field.data.fields?.reduce((acc, subField) => {
                  if (subField.required && subField.type) {
                    if (subField.type !== FormFieldType.FILE) {
                      return {
                        ...acc,
                        [subField.id]: Yup.string()
                          .nullable()
                          .required(`${subField.label} é obrigatório`),
                      };
                    }
                    if (subField.type === FormFieldType.FILE) {
                      return {
                        ...acc,
                        [subField.id]: Yup.mixed()
                          .nullable()
                          .required(`${subField.label} é obrigatório`)
                          .default(value as any),
                      };
                    }
                  }
                  return acc;
                }, {}) ?? {}
              ),
            });
          }
          return schema;
        }),
      });
    }

    return schemaObject;
  });
  return Yup.object().shape(schemaObject);
}

export function generateValidationSchemaList(fields?: FormField[]) {
  const schemaFields: { [key: string]: Yup.AnySchema } = {};

  fields?.forEach(({ value, id, label, type, required, data }) => {
    if (required) {
      if (type === FormFieldType.FILE) {
        schemaFields[id] = Yup.array()
          .of(
            Yup.mixed().required(
              required ? `${label} é obrigatório` : undefined
            )
          )
          .default(value as any);
      }

      if (data?.size?.min) {
        schemaFields[id] = Yup.array().of(
          Yup.string().min(
            data?.size?.min,
            `${label} deve conter no mínimo ${data.size.min} caracteres`
          )
        );
      }
      if (data?.size?.max) {
        schemaFields[id] = Yup.array().of(
          Yup.string().max(
            data?.size?.max,
            `${label} deve conter no máximo ${data.size.max} caracteres`
          )
        );
      }

      if (type !== FormFieldType.FILE) {
        schemaFields[id] = Yup.array().of(
          Yup.string().nullable().required(required ? `${label} é obrigatório` : undefined)
        );
      }

      if (type === FormFieldType.CPF) {
        schemaFields[id] = Yup.array().of(
          Yup.string().test("cpf-valid", "CPF Inválido", (value) =>
            value ? cpf.isValid(value) : false
          )
        );
      }
    }
  });

  return Yup.object().shape(schemaFields);
}

import { FormField, FormFieldType } from "@/services/form/types";

function createSubFieldsValues(data: any, fields: any) {
  return Object.entries(data.fields).map(([key, value]) => {
    const fieldType = fields.find((field: any) => field.id === key)?.type;

    return {
      id: key,
      value: [value ?? null],
      type: fieldType,
      data: getDataByFieldId(fields, key),
    };
  });
}

function getDataByFieldId(fields: FormField[], fieldId: string) {
  return fields.find((field) => field.id === fieldId)?.data;
}

function createFieldValue(value: string | any[], isList: boolean) {
  if (isList) {
    return value;
  }

  if (!isList) {
    return value ? [value] : [null];
  }
}

export function applyTypeOnFields({
  isList = false,
  fields,
  values,
}: {
  fields: FormField[];
  values: { [key: string]: any };
  isList: boolean;
}) {
  const fieldsValues = Object.entries(values);
  return fieldsValues.map(([key, value]) => {
    const typeByFieldId = fields.find((field) => field.id === key)?.type;
    if (typeByFieldId === FormFieldType.CONDITIONAL_SUBFORM && value) {
      const subFields = fields.find((field) => field.id === key)?.data?.fields;
      const parsedSubFields = createSubFieldsValues(Array.isArray(value) ? value[0]?.data : value?.data, subFields);
      return {
        id: key,
        type: typeByFieldId,
        value: [Array.isArray(value) ? value[0].value : value.value ?? null],
        data: { fields: parsedSubFields },
      };
    }

    if (typeByFieldId === FormFieldType.FILE) {
      const _value = createFieldValue(value, isList);
      return {
        id: key,
        type: typeByFieldId,
        value: _value,
        data: getDataByFieldId(fields, key),
      };
    }

    return {
      id: key,
      type: typeByFieldId,
      value: createFieldValue(value, isList),
    };
  });
}

export function generateFormsPositions(positions: number) {
  return Array.from({ length: positions }, (_, i) => i);
}
export function createFormsByValuesLength(fields: FormField[]) {
  return fields.reduce((max, field) => {
    return Math.max(max, field.value.length);
  }, 0);
}

export const verifyValuesForm = (obj: { [key: string]: string[] }) => {
  for (const value of Object.values(obj)) {
    if (!(Array.isArray(value) && value.length > 0 && value[0] !== "")) {
      return false;
    }
  }
  return true;
};

import React, { useCallback, useState } from "react";
import { CepFieldProps } from "../../helpers/types";
import { CepTextField } from "@caju-beneficios/react-web-commons";
import { RemoveMask } from "@/utils/RemoveMask";
import { useViaCep } from "@/hooks/services/viaCep";
import { transformAddressObj } from "./helpers";

export default function CepField({ value, onChange, ...props }: CepFieldProps) {
  const viaCepService = useViaCep();
  const [isLoading, setIsLoading] = useState(false);
  const updateAddressFieldsByZipCode = useCallback(
    async (zipCode: string) => {
      const parsedZipCode = RemoveMask(zipCode);
      setIsLoading(true);
      try {
        const address = await viaCepService.getByCep(parsedZipCode);
        const addressArr = transformAddressObj(address);
        onChange(value, "", { fieldsValues: addressArr });
      } finally {
        setIsLoading(false);
      }
    },
    [value, onChange, viaCepService],
  );

  return (
    <CepTextField
      variant="outlined"
      fullWidth
      size={props.isMobile ? "medium" : "small"}
      label={props.label}
      id={props.id}
      value={value}
      error={!!props.error}
      helperText={props.error}
      disabled={isLoading}
      onChange={(event: any) => {
        const zipCodeValue = event.target.value;

        if (!onChange) return;
        if (zipCodeValue.length === 9) {
          updateAddressFieldsByZipCode(zipCodeValue);
        } else {
          onChange(zipCodeValue);
        }
      }}
    />
  );
}

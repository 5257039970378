export interface FormSection {
  id: string;
  complete: boolean;
  title: string;
}

export interface WelcomeMessage {
  title: string;
  message: string;
  contact: string;
  contactPhone?: string;
  links: { name?: string; url: string }[];
}

export enum ResponseFormStatus {
  NOT_STARTED = "NOT_STARTED",
  FORM_IN_PROGRESS = "FORM_IN_PROGRESS",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  FINISHED = "FINISHED",
}

export interface Registration {
  registrationId: string;
  sponsorId: string;
}

export interface ResponseForm {
  name: string;
  sections: FormSection[];
  admittanceDate: string;
  welcomeMessage: WelcomeMessage;
  status: ResponseFormStatus;
  termOfServicesAcceptanceDate: string;
}

import { FieldsType, FormField, FormFieldType } from "@/services/form/types";
import { RemoveMask } from "@/utils/RemoveMask";
import { format, parse } from "date-fns";
import { FormValues } from "../../FormsByType/DefaultForm";

export function parseDate(date: string) {
  if (!date) return null;
  const parsedDate = parse(date, "yyyy-MM-dd", new Date());

  return format(parsedDate, "yyyy-MM-dd");
}

const getFieldType = (fieldType: FormFieldType) => {
  const specialTypes: {
    [key in FormFieldType]?: FieldsType;
  } = {
    [FormFieldType.DATE]: FieldsType.DATE_FORM_FIELD,
    [FormFieldType.NUMBER]: FieldsType.LONG_FORM_FIELD,
    [FormFieldType.FILE]: FieldsType.FILE_FORM_FIELD,
  };
  if (specialTypes[fieldType]) return specialTypes[fieldType];
  if (!specialTypes[fieldType]) return FieldsType.STRING_FORM_FIELD;
};

function parseFileFieldValue(field: any) {
  return {
    id: field.id,
    value: field.value.map((fieldValue: any, index: number) => {
      if (fieldValue instanceof File) {
        return {
          fileName: fieldValue.name,
          slot: index,
        };
      }
      if (fieldValue?.fileName) {
        return {
          fileName: fieldValue.fileName,
          slot: index,
        };
      }
      if (!fieldValue?.fileName) {
        return null;
      }
      return fieldValue;
    }),
    type: FieldsType.FILE_FORM_FIELD,
  };
}

export function parseFields(fields: any[]): any {
  return fields.map((field) => {
    if (field.type === FormFieldType.DATE) {
      return {
        id: field.id,
        value: field.value.map((val: string) => val ? parseDate(val) : null),
        type: FieldsType.DATE_FORM_FIELD,
      };
    }

    if (field.type === FormFieldType.MULTIDROPDOWN) {
      return {
        id: field.id,
        value: field.value.map((val: string) => {
          if (val) {
            return JSON.stringify(val);
          }
          return null;
        }),
        type: getFieldType(field.type),
      };
    }

    if (field.type === FormFieldType.FILE) {
      return parseFileFieldValue(field);
    }

    if (field.type === FormFieldType.PHONE) {
      return {
        id: field.id,
        value: field.value.map((val: string) => {
          if (val) {
            return RemoveMask(val);
          }
          return null;
        }),
        type: FieldsType.STRING_FORM_FIELD,
      };
    }

    if (field.type === FormFieldType.CONDITIONAL_SUBFORM) {
      return {
        id: field.id,
        value: field.value,
        type: FieldsType.STRING_FORM_FIELD,
        fields: field.data.fields.map((subField: any) => {
          const isFile = subField.type === FormFieldType.FILE;

          return {
            id: subField.id,
            value: isFile
              ? parseFileFieldValue(subField).value ?? null
              : subField.value,
            type: getFieldType(subField.type),
          };
        }),
      };
    }

    return {
      id: field.id,
      value: field.value.map((val: string) => {
        if (val) {
          return val;
        }
        return null;
      }),
      type: getFieldType(field.type),
    };
  });
}

export function getFileFields(formValues: (FormValues | undefined)[]) {
  return formValues
    .map((form) => {
      return {
        formId: form?.formId,
        fields:
          form?.fields.filter((field: FormField) => {
            return field.type === FormFieldType.FILE;

          }) ?? [],
      };
    })
    .filter((form) => {
      return !!form.fields.length;

    });
}

export function getFileConditionalFieldsFile(
  formValues: (FormValues | undefined)[]
) {
  return formValues?.map((form) => {
    const conditionalFields = form?.fields.filter(
      (field: FormField) => field.type === FormFieldType.CONDITIONAL_SUBFORM
    ) as FormField[];
    return {
      formId: form?.formId,
      fields: conditionalFields?.map((field) => {
        const fileFields = field.data?.fields?.filter(
          (subField) => subField.type === FormFieldType.FILE
        );
        return fileFields?.map((fileField) => {
          return {
            ...fileField,
            id: `${field.id}.${fileField.id}`,
          };
        });
      })
        .flat(),
    };
  });
}

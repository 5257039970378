import { useRecaptcha } from "@/hooks/useRecaptcha";
import { RegistrationService } from "@/services/registration";
import React from "react";

export function useAcceptTos(token: string) {
  const { getRecaptcha } = useRecaptcha();
  const [loading, setLoading] = React.useState<boolean>(false);

  const acceptTos = React.useCallback(async () => {
    if (loading) return;
    try {
      const registrationServiceInstane = new RegistrationService(getRecaptcha);
      setLoading(true);
      const data = await registrationServiceInstane.acceptTos(token);
      return data;
    } finally {
      setLoading(false);
    }
  }, [token, loading, getRecaptcha]);

  return { acceptTos, loading };
}

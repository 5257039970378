import React, { useCallback } from "react";
import { FieldConditionalSubFormProps } from "../../helpers/types";
import { FormControlLabel, Grid, Radio } from "@material-ui/core";
import { renderFormField } from "../../helpers";
import * as S from "./styles";

interface Props extends FieldConditionalSubFormProps {}

export default function ConditionalForm(props: Props) {
  const handleChange = useCallback(
    (value: string | null, subFieldId?: string) => {
      if (props.onChange) {
        if (subFieldId) {
          props.onChange({
            ...props.value,
            data: {
              ...props.value?.data,
              fields: {
                ...props.value?.data?.fields,
                [subFieldId]: value,
              },
            },
          });
        }

        if (!subFieldId) {
          props.onChange({
            ...props.value,
            value,
            data: {
              ...props.value?.data,
              fields: {
                ...props.value?.data.fields,
              },
            },
          });
        }
      }
    },
    [props]
  );

  const clearAllSubfields = (value: string) => {
    if (!props.fields) return;
    const subFieldsValues = props.fields.reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: null,
      };
    }, {});

    if (props.onChange) {
      props.onChange({
        value,
        data: {
          fields: { ...subFieldsValues },
        },
      });
    }
  };
  const getSubFieldValueById = useCallback(
    (subFieldId: string) => {
      const value = props?.value?.data?.fields[subFieldId];
      return value === null ? "" : Array.isArray(value) ? value[0] : value;
    },
    [props.value]
  );

  const getErrorField = (fieldId: string) => {
    try {
      return props?.error?.data?.fields[fieldId];
    } catch (error) {
      return "";
    }
  };

  return (
    <div>
      <h4>{props.label}</h4>
      <S.FieldAdditionalText>{props.additionalText}</S.FieldAdditionalText>
      {props.options?.map((opt) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: 8 }}
            key={opt}
          >
            <FormControlLabel
              value="end"
              control={
                <Radio
                  color="primary"
                  data-testId={`radio-${opt}`}
                  checked={opt === props?.value?.value}
                  role="radio"
                  onClick={() => {
                    if (!props.showForOptionValues?.includes(opt)) {
                      clearAllSubfields(opt);
                    } else {
                      handleChange(opt);
                    }
                  }}
                />
              }
              label={opt}
            />
          </div>
        );
      })}
      <Grid container direction="column" spacing={2} style={{ marginTop: 8 }}>
        {props.fields?.map((field) => {
          const subFieldValue = getSubFieldValueById(field.id);
          const enableFields = props.showForOptionValues?.includes(
            props?.value?.value ?? ""
          );
          const errorField = getErrorField(field.id);
          return (
            <Grid item xs={12} key={field.id}>
              {renderFormField(field.type, {
                required: field.required,
                label: field.label,
                id: `${props.id}.data.fields.${field.id}`,
                disabled: !enableFields,
                fields: props.fields,
                onChange: (value: any) => {
                  handleChange(value, field.id);
                },
                value: subFieldValue,
                isMobile: props.isMobile,
                options: field.data?.options,
                error: errorField,
                data: field?.data,
                hidden: field.hidden,
              })}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

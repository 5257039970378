import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

type Props = TextFieldProps & {};
export default function FieldText({ ...props }: Props) {
  return (
    <TextField
      type="text"
      variant="outlined"
      fullWidth
      size="small"
      {...props}
    />
  );
}

import { CSSProperties, ReactNode } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 24,
      paddingTop: 40,
    },
  }),
);

interface Props {
  children: ReactNode,
  style?: CSSProperties,
}

export default function WelcomeContainer(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction='column' style={{ flexGrow: 1, alignItems: "center", width: "100%" }}>
        <Grid item xs={12} sm={12} md={8} style={{ flexGrow: 1, width: "100%", }}>
          {props.children}
        </Grid>
      </Grid>
    </div>
  );
}

import React from "react";
import * as S from "./styles";
import { ImAttachment } from "react-icons/im";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { HiOutlineTrash } from "react-icons/hi";
import { FieldFileProps } from "@/pages/FormBySection/helpers/types";
import { Button } from "@material-ui/core";

interface Props {
  value: FieldFileProps["value"];
  onDelete: () => void;
}
export default function FileSelected(props: Props) {
  const fileName =
    props.value instanceof File ? props.value.name : props.value?.fileName;
  if (!fileName) return null;
  return (
    <S.FileSelected>
      <div>
        <ImAttachment style={{ marginRight: 8 }} size={24} />
        <p>{fileName}</p>
      </div>
      <Button
        variant="text"
        endIcon={<HiOutlineTrash color={Tokens.feedbackColor.error.pure} />}
        onClick={() => {
          props.onDelete();
        }}
        style={{
          borderColor: Tokens.feedbackColor.error.pure,
          color: Tokens.feedbackColor.error.pure,
        }}
      >
        Remover Anexo
      </Button>
    </S.FileSelected>
  );
}

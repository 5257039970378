import {
  FormControl,
  MenuItem,
  SelectProps,
  FormControlProps,
  FormHelperText,
  TextField,
  Box,
} from "@material-ui/core";
import { FieldDropdownProps } from "../../helpers/types";
import { Autocomplete } from "@material-ui/lab";
import FieldTooltip from "../FieldTooltip";

type Props = Omit<SelectProps, "value" | "onChange" | "error"> &
  FieldDropdownProps & {
    size: FormControlProps["size"];
    helperText?: string;
  };

export default function FieldDropdown(props: Props) {
  return (
    <FormControl
      variant="outlined"
      size={props.size}
      fullWidth
      disabled={props.disabled}
      error={!!props.error}
    >
      <Autocomplete
        id="autocomplete-dropdown"
        size="small"
        value={props.value}
        autoHighlight
        clearOnEscape={true}
        disabled={props.disabled}
        options={props.options as string[]}
        getOptionLabel={(option) => option}
        renderOption={(option) => <MenuItem value={option}>{option}</MenuItem>}
        renderInput={(params) => (
          <Box display="flex" alignItems="center">
            <TextField
              error={!!props.error}
              variant="outlined"
              {...params}
              label={props.label}
              inputProps={{
                ...params.inputProps,
                autoComplete: true,
              }}
            />
            {props.id === "BIOLOGICAL_SEX" && (
              <FieldTooltip
                message="Esta pergunta é necessária para registro de colaboradores e serviços
            terceiros, como planos de saúde, que precisam mapear riscos à saúde,
            como câncer de próstata e útero. Respeitamos a identidade de gênero
            de todas as pessoas e pedimos essa informação por razões legais."
              />
            )}
          </Box>
        )}
        onChange={(event: any, newValue, reason) => {
          if (props.onChange && newValue) {
            props.onChange(newValue);
            return;
          }
          if (reason === "clear") {
            props.onChange(null);
            return;
          }
        }}
        noOptionsText="Nenhuma opção encontrada…"
      />
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}

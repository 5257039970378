import React, { useCallback, useMemo } from "react";
import {
  Button,
  FormHelperText,
  IconButton,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { GoDownload, GoUpload } from "react-icons/go";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import { ImAttachment } from "react-icons/im";
import ActionsButtons from "@/components/ActionsButtons";
import useNotification from "@/hooks/useNotification";
import { convertToMB, isExtensionAccept, isFileTooLarge } from "@/utils";
import { FieldLabel } from "../FileField/styles";
import FileSelected from "../FileField/components/FileSelected";
import ConfirmDialog from "@/components/ConfirmDialog";
import { useVisibilty } from "@caju-beneficios/react-web-commons";
import { useFormsResponse } from "@/hooks/services/formsResponse";
import { useLocation, useParams } from "react-router-dom";
import download from "downloadjs";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

type Props = {
  onChange: (file: File | null) => void;
  value: { fileName: string; slot: number } | File | null;
  accept?: string[];
  maxSize?: number;
  label?: string;
  error?: string;
  referenceFileKey: string;
};

function isValidFile(file: File, accept?: string[], maxSize = 5000000) {
  if (!file) {
    return {
      isValid: false,
      msg: "",
    };
  }
  if (isFileTooLarge(file)) {
    return {
      isValid: false,
      msg: `Arquivo maior que ${convertToMB(maxSize)}`,
    };
  }

  if (!isExtensionAccept(file, accept)) {
    return {
      isValid: false,
      msg: "Tipo de arquivo não aceito.",
    };
  }

  return {
    isValid: true,
    msg: "",
  };
}

export default function ReferenceDocFile({
  maxSize = 5000000,
  ...props
}: Props) {
  const params = useParams<{ token: string }>();
  const location = useLocation();
  const locationState = useMemo(
    () =>
      location.state ?? {
        sectionId: "",
        isFromSummary: false,
      },
    [location.state]
  );
  const dialogDelete = useVisibilty();
  const notification = useNotification();
  const service = useFormsResponse(params.token);
  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event?.target?.files[0];

    const { isValid, msg } = isValidFile(file, props?.accept, maxSize);

    if (isValid) {
      notification.success("Arquivo selecionado");
      props.onChange(file);
    }

    if (!isValid) {
      notification.error(msg);
    }
  };

  const handleDownloadReferenceDoc = useCallback(async () => {
    const [fileName, fileKey] = props.referenceFileKey.split("|", 2);

    try {
      const fileUrl = await service.getFileUrl(
        locationState.sectionId,
        fileKey
      );
      if (!fileUrl) return;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      download(blob, fileName);
    } catch (error) {
      notification.error("Erro ao baixar arquivo de referência");
    }
  }, [locationState, notification, props.referenceFileKey, service]);

  if (props.value) {
    return (
      <div>
        <ConfirmDialog
          title="Remover arquivo"
          description="Deseja remover o arquivo selecionado?"
          isOpen={dialogDelete.isVisible}
          onClose={() => dialogDelete.toggle()}
          onConfirm={() => {
            if (props.onChange) {
              props.onChange(null);
              dialogDelete.toggle();
            }
          }}
        />

        <FileSelected
          value={props.value}
          onDelete={() => {
            dialogDelete.toggle();
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <ActionsButtons
        customStyleMenu={{
          marginTop: 50,
        }}
        customActionButtom={
          <div>
            <FieldLabel error={props.error}>
              {props.error ? props.error : props.label}
            </FieldLabel>

            <Button
              startIcon={<ImAttachment />}
              color="primary"
              variant="outlined"
              size="large"
            >
              Anexo
            </Button>
            <Tooltip title="Caso necessário realize o download do arquivo modelo">
              <IconButton aria-label="info" color="primary">
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
      >
        <MenuItem style={{ display: "flex", gap: 8 }} component="label">
          <GoUpload size={22} color={Tokens.interfaceColor.primary.pure} />
          Anexar arquivo
          <input type="file" hidden onChange={handleFile} multiple={false} />
        </MenuItem>

        <MenuItem
          style={{ display: "flex", gap: 8 }}
          component="label"
          onClick={() => handleDownloadReferenceDoc()}
        >
          <GoDownload size={22} color={Tokens.interfaceColor.primary.pure} />
          Baixar modelo
        </MenuItem>
      </ActionsButtons>

      <FormHelperText>
        {props?.accept?.map((acceptExtension) => `${acceptExtension} `)}
        máximo de {convertToMB(maxSize)}mb por arquivo
      </FormHelperText>
    </div>
  );
}

import { FormSection } from "@/services/registration/types";
import { useEffect, useState } from "react";

interface Values {
    completedItems: FormSection[]
    percentComplete: number
    isComplete: boolean
    isItemsEmpty: boolean
}

export function useCompletedSections(items: FormSection[] = []) {

    const [values, setValues] = useState<Values>({
        completedItems: [],
        percentComplete: 0,
        isComplete: false,
        isItemsEmpty: false
    })

    useEffect(() => {
        if (items.length) {
            const completed = items.filter(item => item.complete);
            const percentComp = completed.length / items.length * 100;
            const isItemsCompleted = percentComp === 100;
            setValues({
                completedItems: completed,
                percentComplete: percentComp,
                isComplete: isItemsCompleted,
                isItemsEmpty: percentComp === 0
            })
        }
    }, [items])

    return {
        ...values
    }
}

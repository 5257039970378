import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FormSection } from "@/services/form/types";

function getPreviousSection(currentSection: string, sections?: FormSection[]) {
  if (sections?.length) {
    const index = sections?.findIndex(
      (section) => section.id === currentSection
    );
    const previousSectionId = sections[index - 1]?.id;
    if (previousSectionId) {
      return previousSectionId;
    }
    return null;
  }
}
function getNextSection(currentSection: string, sections?: FormSection[]) {
  if (sections?.length) {
    const index = sections?.findIndex(
      (section) => section.id === currentSection
    );
    const nextSectionId = sections[index + 1]?.id;
    if (nextSectionId) return nextSectionId;
    return null;
  }
}

interface Props {
  currentSection?: string;
  sections?: FormSection[];
}
export function useNavigateSection(props?: Props) {

  const currentSection = props?.currentSection;
  const navigate = useNavigate();

  const next = useCallback(
    (token?: string) => {
      if (!token) {
        throw new Error("token undefined");
      }
      if (!currentSection) return;
      const nextSectionId = getNextSection(currentSection, props.sections);
      if (!nextSectionId) {
        navigate(`/section/form/summary/${token}`);
      }

      if (nextSectionId) {
        navigate(`/section/form/token/${token}`, {
          replace: true,
          state: {
            sectionId: nextSectionId,
          },
        });
      }
    },
    [currentSection, navigate, props?.sections]
  );

  const back = useCallback(
    (token?: string) => {
      if (!token) {
        throw new Error("token undefined");
      }
      if (!currentSection) return;
      const previousSectionId = getPreviousSection(
        currentSection,
        props.sections
      );

      if (!previousSectionId) {
        navigate(`/home/token/${token}`);
      }

      if (previousSectionId) {
        navigate(`/section/form/token/${token}`, {
          replace: true,
          state: {
            sectionId: previousSectionId,
          },
        });
      }
    },
    [currentSection, navigate, props?.sections]
  );

  const goTo = useCallback(
    (sectionId?: string, token?: string, isFromSummary?: boolean) => {
      if (!token) {
        throw new Error("token undefined");
      }
      if (!sectionId) {
        throw new Error("sectionId undefined");
      }
      navigate(`/section/form/token/${token}`, {
        replace: true,
        state: {
          isFromSummary,
          sectionId,
        },
      });
    },
    [navigate]
  );

  return {
    next,
    back,
    goTo,
  };
}

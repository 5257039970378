import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { ImAttachment } from "react-icons/im";
import { FieldFileProps } from "../../helpers/types";
import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import ConfirmDialog from "@/components/ConfirmDialog";
import useNotification from "@/hooks/useNotification";
import { useVisibilty } from "@caju-beneficios/react-web-commons";
import FileSelected from "./components/FileSelected";
import * as S from "./styles";
import { getExtensionFile } from "@/utils";

type Props = Omit<ButtonProps, "value" | "onChange"> & FieldFileProps & {};

function isFileTooLarge(file: File, maxSize: number = 5000000): boolean {
  return file.size > maxSize;
}

function isExtensionAccept(file: File, acceptExtensions?: string[]) {
  const extension = getExtensionFile(file.type);
  return acceptExtensions?.includes(extension);
}

export default function FileField(props: Props) {
  const notification = useNotification();
  const dialogDelete = useVisibilty();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange && event.target.files) {
      const file = event.target.files[0];
      if (!file) {
        notification.error("Arquivo inválido");
        return;
      }

      if (isFileTooLarge(file, props.data?.maxSize)) {
        notification.error("Arquivo maior que 5mb");
        return;
      }

      if (!isExtensionAccept(file, props.data?.accept)) {
        notification.error("Tipo de arquivo não aceito.");
        return;
      }

      notification.success("Arquivo selecionado");
      props.onChange(file);
    }
  };

  const maxSizeFile = props.data?.maxSize
    ? props.data?.maxSize / 1000000
    : null;

  return (
    <div>
      <ConfirmDialog
        title="Remover arquivo"
        description="Deseja remover o arquivo selecionado?"
        isOpen={dialogDelete.isVisible}
        onClose={() => dialogDelete.toggle()}
        onConfirm={() => {
          if (props.onChange) {
            props.onChange(null);
            dialogDelete.toggle();
          }
        }}
      />
      {props.value ? (
        <FileSelected
          value={props.value}
          onDelete={() => {
            dialogDelete.toggle();
          }}
        />
      ) : (
        <div>
          <S.FieldLabel error={props.error}>
            {props.error ? props.error : props.label}
          </S.FieldLabel>
          <Button
            variant="outlined"
            color="primary"
            component="label"
            size={props.size}
            disabled={props.disabled}
            style={{
              borderColor: props.error && Tokens.feedbackColor.error.pure,
              color: props.error && Tokens.feedbackColor.error.pure,
            }}
          >
            <ImAttachment
              style={{
                marginRight: 8,
                fill: props.error
                  ? Tokens.feedbackColor.error.pure
                  : Tokens.interfaceColor.primary.pure,
              }}
              color={Tokens.interfaceColor.primary.pure}
            />
            Anexar
            <input
              type="file"
              hidden
              onChange={handleFileChange}
              multiple={false}
            />
          </Button>
          {!props.disabled ? (
            <S.HelperText error={props.error}>
              {props.data?.accept?.map(
                (acceptExtension) => `${acceptExtension} `,
              )}
              máximo de {maxSizeFile}mb por arquivo
            </S.HelperText>
          ) : null}
        </div>
      )}
    </div>
  );
}

import { useRecaptcha } from "@/hooks/useRecaptcha";
import { FormService } from "@/services/form";
import { useState, useCallback } from "react";

export function useFormSubmit() {
  const [loading, setLoading] = useState(false);
  const { getRecaptcha } = useRecaptcha();

  const submit = useCallback(async (token?: string) => {
    if (!token) {
      throw new Error("token undefined");
    }
    const formServiceInstance = new FormService(getRecaptcha);
    try {
      setLoading(true);
      await formServiceInstance.submit(token);
    } finally {
      setLoading(false);
    }
  }, [getRecaptcha]);

  return {
    loading,
    submit,
  };
}

import { FormField, FormFieldType } from "@/services/form/types";
import * as S from "../styles";
import { getFormValuesComponent } from "../helpers";

export default function LabelValueConditional({
  label,
  value,
  isMobile,
  fields,
}: {
  label: string;
  value: string | null;
  type: FormFieldType;
  isMobile: boolean;
  fields?: FormField[];
}) {
  return (
    <div>
      <S.FormInfo isMobile={isMobile}>
        <S.FieldLabel>{label}</S.FieldLabel>
        <S.FieldValue>{value}</S.FieldValue>
      </S.FormInfo>
      {fields?.map((subField) => getFormValuesComponent(subField, isMobile))}
    </div>
  );
}

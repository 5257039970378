import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function useRecaptcha() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getRecaptcha = useCallback(async (): Promise<string | undefined> => {
    if (!executeRecaptcha) return;

    return await executeRecaptcha();
  }, [executeRecaptcha]);

  return { getRecaptcha };
}

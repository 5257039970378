import { useCallback } from "react";
import * as ViaCepService from "@/services/viaCep";
import { AddressByZipCode } from "@/services/viaCep/types";

const addressEmpty: AddressByZipCode = {
  bairro: "",
  cep: "",
  localidade: "",
  logradouro: "",
  uf: "",
};

function transformData(json: any): AddressByZipCode {
  return {
    cep: json.cep.replace("-", ""),
    logradouro: json.logradouro,
    bairro: json.bairro,
    localidade: json.localidade,
    uf: json.uf,
  };
}

export function useViaCep() {
  const getByCep = useCallback(async (zipCode: string) => {
    const defaultResult = {
      ...addressEmpty,
      cep: zipCode
    };
    try {
      const data = await ViaCepService.getAddressByCep(zipCode);
      return data.cep ? transformData(data) : transformData(defaultResult);
    } catch (error) {
      return defaultResult;
    }
  }, []);

  return { getByCep };
}

import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";

export const Container = styled.div``;

export const FieldLabel = styled.p`
  color: ${Tokens.neutralColor.medium[400]};
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  width: 200px;
`;

export const FieldValue = styled.p`
  margin: 0;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  word-break: break-all;
`;

export const FormInfo = styled.div<{ isMobile: boolean }>`
  display: grid;
  margin: 16px 0px;
  /* justify-content: ${(props) => (props.isMobile ? "" : "")}; */
  /* flex-direction: ${(props) => (props.isMobile ? "column" : "row")}; */
  grid-template-columns: ${(props) => (props.isMobile ? "auto" : "300px auto")};
`;

export const SectionTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

export const SectionConfig = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const SectionEdit = styled.div`
  cursor: pointer;
  display: flex;
  gap: 8px;
  p {
    color: ${Tokens.interfaceColor.primary.pure};
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: ${Tokens.interfaceColor.primary.pure};
  }
`;

export const FormTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #000;
`;

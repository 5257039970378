import { FileValue } from "@/services/form/types";
import * as S from "../styles";

export default function LabelAndValueFile({
  value,
  label,
  isMobile,
}: {
  value: FileValue | null
  label: string;
  isMobile: boolean;
}) {
  return (
    <S.FormInfo isMobile={isMobile}>
      <S.FieldLabel>{label}</S.FieldLabel>
      <S.FieldValue>{value?.fileName}</S.FieldValue>
    </S.FormInfo>
  );
}

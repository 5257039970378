import { OnboardingApi } from "@/lib/axios";
import {
  ReponseFormData,
  ReponsePutFormData,
  ResponseSection,
  UpdateSectionPayload,
} from "./types";

export class FormService {
  constructor(private getRecaptcha: () => Promise<string | undefined>) {}

  async getSection(token: string, sectionId: string) {
    const recaptcha = await this.getRecaptcha();
    const url = `/v1/form_responses/${token}/sections/${sectionId}`;
    const response = await OnboardingApi(recaptcha).get<ResponseSection>(url);
    return response.data;
  }

  async updateSection(
    token: string,
    sectionId: string,
    payload: UpdateSectionPayload,
  ) {
    const recaptcha = await this.getRecaptcha();

    const url = `/v1/form_responses/${token}/sections/${sectionId}`;

    const response = await OnboardingApi(recaptcha).put<ReponsePutFormData>(
      url,
      payload,
    );

    return response.data;
  }

  async createFormData(
    token: string,
    sectionId: string,
    payload: { extension: string; fileId: string; slot?: number },
  ) {
    const recaptcha = await this.getRecaptcha();

    const url = `/v1/form_responses/${token}/sections/${sectionId}/file_form_data`;

    const response = await OnboardingApi(recaptcha).post<ReponseFormData>(
      url,
      payload,
    );

    return response.data;
  }

  async pathFile(
    token: string,
    sectionId: string,
    payload: { fieldPath: string; value: string; position?: number },
  ) {
    const recaptcha = await this.getRecaptcha();

    const url = `/v1/form_responses/${token}/sections/${sectionId}`;

    await OnboardingApi(recaptcha)
      .patch(url, payload)
      .catch((e) => console.error(e));
  }

  async submit(token: string) {
    const recaptcha = await this.getRecaptcha();

    const url = `/v1/registrations/${token}/status/awaiting_approval`;

    const response = await OnboardingApi(recaptcha).put(url);
    return response.data;
  }
}

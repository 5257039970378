import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Tokens } from '@caju-beneficios/react-web-commons/dist/configs';
import WelcomeFooter from '@/components/WelcomeFooter';
import { useState } from 'react';
import { useAcceptTos } from '@/hooks/services/registration/useAcceptTOS';
import { Variables } from '@/configs/variables';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

interface Props {
    onNext: () => void
    onPrevious: () => void
    token: string
}

export default function AdmittanceTOS({
    onNext,
    onPrevious,
    token
}: Props) {
    const classes = useStyles();

    const tosService = useAcceptTos(token)

    const [tosChecked, setTosChecked] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTosChecked(event.target.checked);
    };

    const handleClickNext = async () => {
      await tosService.acceptTos()
      onNext()
    }

    const handleClickPrevious = () => {
        onPrevious()
    }

    return (
        <div className={classes.root}>
            <div style={{  padding: 24,
                paddingTop: 40 }}>
                <Typography variant='subtitle1' gutterBottom style={{ color: Tokens.neutralColor.dark[100] }}>
                    Termo de consentimento
                </Typography>
            </div>
            <div style={{ marginTop : 24 }} />
            <div style={{ flexGrow: 1 }}>
                <iframe
                    width='100%'
                    height='calc(100vh - 380px)'
                    style={{
                        display: 'block',
                        height: 'calc(100vh - 380px)',
                        border: 'none'
                    }}
                    title='Termos de consentimento'
                    src={Variables.termOfConsentimentUrl}></iframe>
            </div>
            <WelcomeFooter
                onSave={handleClickNext}
                onBack={handleClickPrevious}
                disableSubmit={!tosChecked}
            >
                <div style={{
                    padding: '24px 0 0'
                }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tosChecked}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Declaro que li e aceito os termos e condições"
                    />
                </div>
            </WelcomeFooter>
        </div>
    );
}

import { Tokens } from "@caju-beneficios/react-web-commons/dist/configs";
import styled from "styled-components";

export const FieldLabel = styled.p<{ error?: string }>`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) =>
    props.error
      ? Tokens.feedbackColor.error.pure
      : Tokens.neutralColor.medium["400"]};
  margin-bottom: 16px;
`;
export const HelperText = styled.p<{ error?: string }>`
  color: ${Tokens.interfaceColor.primary.pure};
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  font-size: 12px;
  color: ${(props) =>
    props.error
      ? Tokens.feedbackColor.error.pure
      : Tokens.neutralColor.medium["400"]};
`;

